import { connector } from './connector';

export async function GET_SETTINGS(school_id, callback, onError) {
	try {
		let url = `/settings?school_id=${school_id}`;
		let res = await connector.get(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function GET_REPORT_SETTINGS(school_id, callback, onError) {
	try {
		let url = `/report-settings?school_id=${school_id}`;
		let res = await connector.get(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function POST_SETTINGS(settings, callback, onError) {
	if (settings) {
		try {
			let url = `/settings`;
			let res = await connector.post(`${url}`, settings);
			let { data, message } = res.data;
			let status = res.data.status;
			status === 'success' && callback && callback(data, message);
			if (status !== 'success') throw res;
			return data;
		} catch (err) {
			onError && onError(err);
			return false;
		}
	}
}

export async function POST_REPORT_SETTINGS(settings, school_id, callback, onError) {
	if (settings) {
		try {
			let url = `/report-settings?school_id=${school_id}`;
			let res = await connector.post(`${url}`, settings);
			let { data, message } = res.data;
			let status = res.data.status;
			status === 'success' && callback && callback(data, message);
			if (status !== 'success') throw res;
			return data;
		} catch (err) {
			onError && onError(err);
			return false;
		}
	}
}

export async function GET_LOGO() {
	try {
		let url = `/settings/logo`;
		let res = await connector.get(`${url}`);
		let { data } = await res.data;
		let status = res.data.status;
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		return false;
	}
}

export async function POST_LOGO(logo, callback, onError) {
	if (logo) {
		try {
			let uploadLogo = { logo: logo };
			let url = `/settings/logo`;
			let res = await connector.post(`${url}`, uploadLogo);
			let { data, message } = res.data;
			let status = res.data.status;
			status === 'success' && callback && callback(data, message);
			if (status !== 'success') throw res;
			return data;
		} catch (err) {
			onError && onError(err);
			return false;
		}
	}
}

export async function GET_SIGNATURE() {
	try {
		let url = `/settings/signature`;
		let res = await connector.get(`${url}`);
		let { data } = await res.data;
		let status = res.data.status;
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		return false;
	}
}

export async function POST_SIGNATURE(signature, callback, onError) {
	if (signature) {
		try {
			let uploadSignature = { signature: signature };
			let url = `/settings/signature`;
			let res = await connector.post(`${url}`, uploadSignature);
			let { data, message } = res.data;
			let status = res.data.status;
			status === 'success' && callback && callback(data, message);
			if (status !== 'success') throw res;
			return data;
		} catch (err) {
			onError && onError(err);
			return false;
		}
	}
}

export async function GET_GRADES(callback, onError) {
	try {
		let url = `/settings/get-grades`;
		let res = await connector.get(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function POST_GRADES(score, classarms, callback, onError) {
	if (score && classarms) {
		try {
			let payload = { single_score: score, checked_arms: classarms };
			let url = `/settings/set-grades`;
			let res = await connector.post(`${url}`, payload);
			let { data, message } = res.data;
			let status = res.data.status;
			status === 'success' && callback && callback(data, message);
			if (status !== 'success') throw res;
			return data;
		} catch (err) {
			onError && onError(err);
			return false;
		}
	}
}

// export async function POST_SETTINGS(settings, term, session, callback, onError) {

// }

export async function POST_PUBLISH_RESULT(callback, onError) {
	try {
		let url = `/settings/publish-result`;
		let res = await connector.post(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function PUT_UNPUBLISH_RESULT(callback, onError) {
	try {
		let url = `/settings/unpublish-result`;
		let res = await connector.put(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function GET_GRADING_SCHEME(callback, onError) {
	try {
		let url = `/settings/grading-scheme`;
		let res = await connector.get(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function POST_GRADING_SCHEME(grading_scheme, classarms, callback, onError) {
	if (grading_scheme && classarms) {
		try {
			let payload = { grading_scheme: grading_scheme, checked_arms: classarms };
			let url = `/settings/set-grading-scheme`;
			let res = await connector.post(`${url}`, payload);
			let { data, message } = res.data;
			let status = res.data.status;
			status === 'success' && callback && callback(data, message);
			if (status !== 'success') throw res;
			return data;
		} catch (err) {
			onError && onError(err);
			return false;
		}
	}
}

export async function GET_MODULES(callback, onError) {
	try {
		let url = `/settings/modules`;
		let res = await connector.get(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function CHECK_TOKEN() {
	try {
		let url = `/settings/token`;
		let res = await connector.get(`${url}`);
		// let { data, message } = res.data;
		let status = res.data.status;
		if (status === 'success') {
			return true;
		}
		if (status !== 'success') return false;
	} catch (err) {
		return false;
	}
}

export async function GET_USER(callback, onError) {
	try {
		let url = `/settings/user`;
		let res = await connector.get(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}
